@import "@/utils/mixins";
@import "@/utils/variables";

.label {
	font-size: 1.8rem;
	margin-bottom: 20px;
	display: block;
	color: $deep-green;
	font-weight: $bold-base;
	display: block;
	line-height: 2.2rem;

	@include tablet() {
		font-size: 2rem;
		line-height: 2.4rem;
		margin-bottom: $gutter * 2;
	}

	span {
		color: lighten($color-darkest, 20%);
		display: block;
		margin-top: $gutter / 2;
		font-size: 1.4rem;
		line-height: 16px;
		color: $grey;
		font-weight: $normal-base;
	}

	&.narrow {
		margin-bottom: $gutter / 2;
	}

	&.tiny {
		margin-bottom: 5px;
	}

	&.small {
		font-weight: $bold-base;
		font-size: 1.6rem;
		line-height: 2rem;
		margin-bottom: $gutter / 2;
	}

	&.labelInside {
		position: absolute;
		z-index: 2;
		top: 10px;
		left: 0;
		margin: 0;
		width: 100%;
		color: lighten($color-darkest, 25%);
		transition: transform 0.4s;
		text-align: center;

		&.labelInsideActive {
			transform: translate(0px, -11px) scale(0.8);
		}
	}

	&.noMargin {
		margin-bottom: 0;
	}
}