@import "@/utils/mixins";
@import "@/utils/variables";

.item {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: nowrap;
}

.deleteIcon {
	margin-left: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	color: $grey;

	&:hover,
	&:focus {
		color: darken($grey, 20%);
	}
}

ul.recipeList {
	padding-left: 0px;
	list-style-type: disc;

	.recipeListItem {
		font-weight: $normal-base;
		font-size: 1.6rem;
		line-height: 2rem;
		color: $grey;
		margin-bottom: 5px;
		display: list-item;
	}
}

.itemTopLine {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 5px;
	line-height: 1.8rem;

	p {
		margin-bottom: 0;
	}
}

.scoopsToggle {
	margin-bottom: 20px;
}

.title {
	font-size: 1.6rem;
	margin-bottom: 0px;

	display: block;

	&.titleLink {
		text-decoration: underline;
		cursor: pointer;
	}
}

.extras {
	font-size: 1.4rem;
}

.price {
	display: flex;
}

.itemContents {
	width: 100%;
	max-width: 80px;
	margin: 10px 0 0;
}

.itemContents p {
	color: $grey;
	align-self: center;
	margin-bottom: 0;
}

.itemWeight {
	grid-column: span 1;
	vertical-align: center;
	margin-bottom: 0;
}

.summaryItems {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.itemQty {
	width: 100px;
}

.itemTitle {
	font-size: 1.6em;
}
.itemQuantity {
	font-size: 1.4em;
	color: $grey;
}

.itemSize {
	font-size: 1.4em;
}

.itemPrice {
	align-self: flex-end;
}
