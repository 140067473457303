@import "src/styles/utils/variables";
@import "src/styles/utils/mixins";

.offerBanner {
	width: 100%;
	text-align: center;
	background-color: $orange;
	color: $white;
	padding: 10px 0;

	&.background_orange {
		background-color: $orange;
	}

	&.background_purple {
		background-color: $purple;
	}

	@include tablet() {
		padding: 15px 0;
	}

	&.inHeader {
		padding: 6px 0;
		align-self: flex-end;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;

		@include tabletLarge() {
			width: auto;
			align-self: flex-end;
			position: relative;
		}

		.content {
			font-size: 1.2rem;
			@include mobileMedium() {
				font-size: 1.6rem;
			}

			&:before {
				margin-right: 0;
			}

			&:after {
				margin-left: 0;
			}

			.secondDiscount {
				display: inline;
				margin-left: 5px;
			}
		}
	}

	.content {
		color: $white;
		margin: 0;
		font-weight: 400;
		position: relative;
		line-height: 2.4rem;
		display: inline-block;
		max-width: 90%;

		&:not([class*="noIllustrations"]) {
			&::after,
			&::before {
				background-repeat: no-repeat;
				background-position: center;
				background-size: 100% auto;
				position: absolute;
				top: 50%;
				display: inline-block;
				width: 24px;
				height: 24px;
				content: "";
			}

			&::before {
				background-image: url("/images/icon-NarrowBurst.svg");
				left: -24px;
				transform: scaleX(-1) translateY(-50%);
			}

			&::after {
				background-image: url("/images/icon-NarrowBurst.svg");
				right: -24px;
				transform: translateY(-50%);
			}
		}
	}
}

.secondDiscount {
	display: inline-block;
	margin-left: 5px;
}
