@import "src/styles/utils/variables";
@import "src/styles/utils/mixins";

.priceAndDiscount {
	display: inline-flex;
	text-align: right;
	margin-left: auto;
	font-size: 1.8rem;
	font-weight: $bold-base;
	color: $purple;
	gap: 0px;
	line-height: 2rem;

	&.twoLines {
		display: block;
		text-align: center;

		del {
			margin-right: 0;
			margin-bottom: 2px;
			display: block;
			font-size: 1.6rem;
			line-height: 1.8rem;
		}
	}

	&.centerSelf {
		margin-right: auto;
	}

	& p {
		margin-bottom: 0;
		line-height: 2rem;
	}

	&.plainColor {
		color: $grey;
		font-size: 1.4rem;
		font-weight: normal;
	}

	&.primaryColor {
		color: $yellow;

		.perDay {
			color: $white;
		}

		del {
			& .perDay {
				color: $faded-deep-green;
			}
		}
	}

	&.secondaryColor {
		color: $orange;

		.perDay {
			color: $deep-green;
		}

		del {
			& .perDay {
				color: $faded-deep-green;
			}
		}
	}

	.per {
		font-size: 1.4rem;
	}

	del {
		margin-right: 5px;
		font-weight: $normal-base;
		color: $faded-deep-green;

		& .perDay {
			color: $faded-deep-green;
		}
	}

	.voucherTag {
		font-size: 1.4rem;
		color: $light-grey;
		margin-left: 10px;
		white-space: nowrap;
	}

	.smallPrice{
		font-size: 0.90em;
	}
}
